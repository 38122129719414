// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aesthetische-dermatologie-augenlidverbesserung-schlupflider-traenensaecke-js": () => import("./../../../src/pages/aesthetische-dermatologie/augenlidverbesserung-schlupflider-traenensaecke.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-augenlidverbesserung-schlupflider-traenensaecke-js" */),
  "component---src-pages-aesthetische-dermatologie-behandlung-mimischer-faeltchen-mit-botox-js": () => import("./../../../src/pages/aesthetische-dermatologie/behandlung-mimischer-faeltchen-mit-botox.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-behandlung-mimischer-faeltchen-mit-botox-js" */),
  "component---src-pages-aesthetische-dermatologie-behandlung-statischer-faeltchen-mit-fillern-js": () => import("./../../../src/pages/aesthetische-dermatologie/behandlung-statischer-faeltchen-mit-fillern.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-behandlung-statischer-faeltchen-mit-fillern-js" */),
  "component---src-pages-aesthetische-dermatologie-fadenlifting-js": () => import("./../../../src/pages/aesthetische-dermatologie/fadenlifting.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-fadenlifting-js" */),
  "component---src-pages-aesthetische-dermatologie-js": () => import("./../../../src/pages/aesthetische-dermatologie.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-js" */),
  "component---src-pages-aesthetische-dermatologie-koerper-contouring-mit-injektionslipolyse-js": () => import("./../../../src/pages/aesthetische-dermatologie/koerper-contouring-mit-injektionslipolyse.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-koerper-contouring-mit-injektionslipolyse-js" */),
  "component---src-pages-aesthetische-dermatologie-medizinisches-peeling-js": () => import("./../../../src/pages/aesthetische-dermatologie/medizinisches-peeling.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-medizinisches-peeling-js" */),
  "component---src-pages-aesthetische-dermatologie-mesotherapie-js": () => import("./../../../src/pages/aesthetische-dermatologie/mesotherapie.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-mesotherapie-js" */),
  "component---src-pages-aesthetische-dermatologie-micro-needling-js": () => import("./../../../src/pages/aesthetische-dermatologie/micro-needling.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-micro-needling-js" */),
  "component---src-pages-aesthetische-dermatologie-narbenbehandlung-js": () => import("./../../../src/pages/aesthetische-dermatologie/narbenbehandlung.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-narbenbehandlung-js" */),
  "component---src-pages-aesthetische-dermatologie-pelleve-schonende-hautstraffung-mittels-radiofrequenz-js": () => import("./../../../src/pages/aesthetische-dermatologie/pelleve-schonende-hautstraffung-mittels-radiofrequenz.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-pelleve-schonende-hautstraffung-mittels-radiofrequenz-js" */),
  "component---src-pages-aesthetische-dermatologie-radiofrequenz-needling-js": () => import("./../../../src/pages/aesthetische-dermatologie/radiofrequenz-needling.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-radiofrequenz-needling-js" */),
  "component---src-pages-aesthetische-dermatologie-vampir-lift-prp-therapie-js": () => import("./../../../src/pages/aesthetische-dermatologie/vampir-lift-prp-therapie.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-vampir-lift-prp-therapie-js" */),
  "component---src-pages-aesthetische-dermatologie-volumenfiller-js": () => import("./../../../src/pages/aesthetische-dermatologie/volumenfiller.js" /* webpackChunkName: "component---src-pages-aesthetische-dermatologie-volumenfiller-js" */),
  "component---src-pages-aktuelles-informationen-zu-corona-js": () => import("./../../../src/pages/aktuelles/informationen-zu-corona.js" /* webpackChunkName: "component---src-pages-aktuelles-informationen-zu-corona-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-anfahrt-js": () => import("./../../../src/pages/kontakt/anfahrt.js" /* webpackChunkName: "component---src-pages-kontakt-anfahrt-js" */),
  "component---src-pages-kontakt-datenschutz-js": () => import("./../../../src/pages/kontakt/datenschutz.js" /* webpackChunkName: "component---src-pages-kontakt-datenschutz-js" */),
  "component---src-pages-kontakt-impressum-js": () => import("./../../../src/pages/kontakt/impressum.js" /* webpackChunkName: "component---src-pages-kontakt-impressum-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-medizinische-dermatologie-akne-und-aknenarben-js": () => import("./../../../src/pages/medizinische-dermatologie/akne-und-aknenarben.js" /* webpackChunkName: "component---src-pages-medizinische-dermatologie-akne-und-aknenarben-js" */),
  "component---src-pages-medizinische-dermatologie-ambulante-operationen-und-dermatologische-tumorchirurgie-js": () => import("./../../../src/pages/medizinische-dermatologie/ambulante-operationen-und-dermatologische-tumorchirurgie.js" /* webpackChunkName: "component---src-pages-medizinische-dermatologie-ambulante-operationen-und-dermatologische-tumorchirurgie-js" */),
  "component---src-pages-medizinische-dermatologie-haarausfall-bei-mann-und-frau-js": () => import("./../../../src/pages/medizinische-dermatologie/haarausfall-bei-mann-und-frau.js" /* webpackChunkName: "component---src-pages-medizinische-dermatologie-haarausfall-bei-mann-und-frau-js" */),
  "component---src-pages-medizinische-dermatologie-hautkrebsvorsorge-js": () => import("./../../../src/pages/medizinische-dermatologie/hautkrebsvorsorge.js" /* webpackChunkName: "component---src-pages-medizinische-dermatologie-hautkrebsvorsorge-js" */),
  "component---src-pages-medizinische-dermatologie-js": () => import("./../../../src/pages/medizinische-dermatologie.js" /* webpackChunkName: "component---src-pages-medizinische-dermatologie-js" */),
  "component---src-pages-medizinische-dermatologie-klassische-dermatologische-erkankungen-js": () => import("./../../../src/pages/medizinische-dermatologie/klassische-dermatologische-erkankungen.js" /* webpackChunkName: "component---src-pages-medizinische-dermatologie-klassische-dermatologische-erkankungen-js" */),
  "component---src-pages-medizinische-dermatologie-nagelpilzerkrankungen-js": () => import("./../../../src/pages/medizinische-dermatologie/nagelpilzerkrankungen.js" /* webpackChunkName: "component---src-pages-medizinische-dermatologie-nagelpilzerkrankungen-js" */),
  "component---src-pages-medizinische-dermatologie-rosacea-und-couperose-js": () => import("./../../../src/pages/medizinische-dermatologie/rosacea-und-couperose.js" /* webpackChunkName: "component---src-pages-medizinische-dermatologie-rosacea-und-couperose-js" */),
  "component---src-pages-medizinische-dermatologie-sonnenlichtschaeden-der-haut-und-hautkrebsvorstufen-js": () => import("./../../../src/pages/medizinische-dermatologie/sonnenlichtschaeden-der-haut-und-hautkrebsvorstufen.js" /* webpackChunkName: "component---src-pages-medizinische-dermatologie-sonnenlichtschaeden-der-haut-und-hautkrebsvorstufen-js" */),
  "component---src-pages-medizinische-dermatologie-venenleiden-js": () => import("./../../../src/pages/medizinische-dermatologie/venenleiden.js" /* webpackChunkName: "component---src-pages-medizinische-dermatologie-venenleiden-js" */),
  "component---src-pages-medizinische-dermatologie-warzenbehandlung-js": () => import("./../../../src/pages/medizinische-dermatologie/warzenbehandlung.js" /* webpackChunkName: "component---src-pages-medizinische-dermatologie-warzenbehandlung-js" */),
  "component---src-pages-plastische-chirurgie-js": () => import("./../../../src/pages/plastische-chirurgie.js" /* webpackChunkName: "component---src-pages-plastische-chirurgie-js" */),
  "component---src-pages-praxis-anfahrt-js": () => import("./../../../src/pages/praxis/anfahrt.js" /* webpackChunkName: "component---src-pages-praxis-anfahrt-js" */),
  "component---src-pages-praxis-dr-med-michael-h-krokowski-js": () => import("./../../../src/pages/praxis/dr-med-michael-h-krokowski.js" /* webpackChunkName: "component---src-pages-praxis-dr-med-michael-h-krokowski-js" */),
  "component---src-pages-praxis-freie-stellen-js": () => import("./../../../src/pages/praxis/freie-stellen.js" /* webpackChunkName: "component---src-pages-praxis-freie-stellen-js" */),
  "component---src-pages-praxis-js": () => import("./../../../src/pages/praxis.js" /* webpackChunkName: "component---src-pages-praxis-js" */),
  "component---src-pages-praxis-mitgliedschaften-js": () => import("./../../../src/pages/praxis/mitgliedschaften.js" /* webpackChunkName: "component---src-pages-praxis-mitgliedschaften-js" */),
  "component---src-pages-praxis-praxis-team-js": () => import("./../../../src/pages/praxis/praxis-team.js" /* webpackChunkName: "component---src-pages-praxis-praxis-team-js" */)
}

